<template>
  <div>

    <talk-schedule-timeline
      :schedules="schedules"
      :tutors="tutors"
      :dates="dates"
      :config="config"
    />

  </div>
</template>

<script>

import Talk from '@/models/Talk'
import TalkScheduleTimeline from '@/components/TalkSchedule/Timeline.vue'

export default {
  name: 'AdminTalkWeekSchedule',
  components: {
    TalkScheduleTimeline,
  },
  data() {
    return {
      loading: true,
      schedules: [],
      tutors: [],
      dates: [],
      config: {
        time_width: 200,
        time: [
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
        ],
        minute: [
          0,
          1,
          2,
          3,
          4,
          5,
        ],
        minute_group: [
          '00',
          '30',
        ],
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      const response = await Talk.week()
      this.schedules = response.data.scheduleData
      this.tutors = response.data.tutors
      this.dates = response.data.dates
      this.loading = false
      // eslint-disable-next-line
      $(document).ready(function(){
        // eslint-disable-next-line
        $(".timeline-content").draggable({ handle: '.header-row', axis: 'x' });
      })
    },
  },
}
</script>

<style lang="scss">

</style>
